
<template>
  <div class="department-statistic-wrapper flex">
    <d-search :searchData="searchData" @searchEvent="searchList" @exportEvent="exportList" @resetEvent="reset"></d-search>
    <div class="inline-flex contentBox">
      <d-table :tableData="tableData" :columnData="columnData" class="contentTable"></d-table>
    </div>
    <d-paging :pager="pager" @change="searchList"></d-paging>
  </div>
</template>

<script>
import DSearch from "@/components/d-search";
import DTable from "@/components/d-table";
import DPaging from "@/components/d-paging";
import {onDownload} from "@/utils/utils";
import {removeLeaf} from "@/utils/utils";
export default {
  name: "departmentStatistic",
  components: {DPaging, DTable, DSearch},
  data() {
    return {
      searchData: {
        searchItem: [
          {label:'处理部门',value:'deptId',type:'linkageSelect',options:[],filterable:true,
            optionProps:{
              label:"deptName",value:"id",emitPath:false,children:"children"
            }},
          {label:'工单解决时间',value:'time',type:'commonDatetime'},
        ],
        searchBtn: {
          queryBtn: true,
          resetBtn: true,
          exportBtn:true
        },
        params: {
          deptId:'',
          time:['','']
        }
      },
      tableData: [],
      columnData: [
        {label: '处理部门', prop: 'handleDeptName'},
        {label: '已完成工单', prop: 'finishCount'},
        {label: '移交', prop: 'handoverCount'},
        {label: '好评', prop: 'goodCount'},
        {label: '差评', prop: 'badCount'},
        {label: '一般评价', prop: 'commonCount'},
        {label: '默认评价', prop: 'normalCount'},
        {label: '平均响应时长', prop: 'avgTime'}
      ],
      pager: {
        count: 0,
        page: 1,
        rows: 10,
      }
    }
  },
  mounted(){
    this.getDepartmentList();
    this.searchList();
  },
  methods:{
    getDepartmentList(){
      this.$api.sysapi.marketPeopleTree().then(({data}) => {
        this.searchData.searchItem.forEach(ele => {
          if (ele.value === 'deptId') {
            ele.options = data.children.map(e=>(delete e.children,e)).filter(e=>(e.deptName!="商户"&&!e.userName))
          }
        })
      })
    },

    _getParams(){
      return {
        startTime:(this.searchData.params.time&&this.searchData.params.time[0])||'',
        endTime:(this.searchData.params.time&&this.searchData.params.time[1])||'',
        deptId:this.searchData.params.deptId,
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
      }
    },

    // 查询列表
    searchList(){
      this.$api.tour.departmentStatistic(this._getParams()).then(({data})=>{
        this.tableData = data.list;
        this.pager.count = data.total;
      })
    },

    // 导出
    exportList(){
      this.$api.tour.exportDepartmentStatistic(this._getParams()).then(({headers,data})=>{
        onDownload(headers,data)
      })
    },

    reset(){
      this.pager.page = 1;
      this.pager.rows = 10;
      this.searchList()
    }
  }
}
</script>

<style scoped>

</style>